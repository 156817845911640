.footer {
    font-family: Lato,Arial,Helvetica,sans-serif;
    background: linear-gradient(90deg,#b93c3c,#a40000);
    padding: 30px 0;
    min-height: 342px;
}

.footer__reviews {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    color: #fff;
}

.footer__reviews-recent {
    width: 50%;
    margin: 0;
    overflow: hidden;
    position: relative;
}

.footer__swiper-title {
    margin: 0 0 20px;
    font-size: 15pt;
    font-weight: 500;
}

.review {
    margin-left: 29px;
    position: relative;
    text-indent: 10px;
    font-weight: 400;
    background-color: #e3e3e3;
    max-width: 517px;
    min-height: 135px;
    border-radius: 10px;
    color: #000;
    padding: 15px 20px 30px 30px;
    height: 189px;
}

.review-image {
    position: absolute;
    left: 0;
    color: rgba(164,0,0,.643137);
}

.review__span {
    font-style: italic;
    font-size: 14pt;
    color: #3e3e3e;
    position: absolute;
    bottom: 10px;
    right: 20px;
}

.arrow-review-prev {
    left: 0;
    color: #fff;
    z-index: 1;
    top: 50%;
    transition: all .2s ease-in-out;
}
.arrow-review-prev:hover {
    color: #ffb203;
}
.arrow-review-next {
    color: #fff;
    z-index: 1;
    top: 50%;
    transition: all .2s ease-in-out;
}
.arrow-review-next:hover {
    color: #ffb203;
}


.footer__form {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;
}

.footer__form-title {
    font-size: 15pt;
    font-weight: 500;
    margin: 0 0 10px;
    grid-column: span 3;
    justify-self: end;
}

.footer__form-input {
    background-color: #e3e3e3;
    border-radius: 10px;
    font-size: 11pt;
    color: #3e3e3e;
    width: 156px;
    height: 32px;
    border: 1px solid #e3e3e3;
    transition: all .2s ease-in-out;
    appearance: none;
    box-sizing: border-box;
}
.footer__form-input::placeholder {
    text-align: center;
}
.footer__form-input_select {
    text-align: center;
}
.footer__form-input_error {
    border-color: rgba(255,0,0,.427451);
    border-width: 4px;
}

.footer__form-textarea {
    background-color: #e3e3e3;
    border-radius: 10px;
    font-size: 11pt;
    color: #3e3e3e;
    grid-column: span 2;
    grid-row: span 2;
    width: 374px;
    height: 74px;
    border: 1px solid #e3e3e3;
    transition: all .2s ease-in-out;
    resize: none;
    padding: 4px;
    box-sizing: border-box;
}
.footer__form-textarea::placeholder{
    text-align: center;
}
.footer__form-textarea_error {
    border-color: rgba(255,0,0,.427451);
    border-width: 4px;
}

.footer__form-submit {
    text-align: center;
    color: #000;
    height: 28px;
    border: none;
    margin: 0;
    background-color: #ffc43a;
    border-radius: 10px;
    width: 156px;
    transition: all .2s ease-in-out;
    cursor: pointer;
    font: inherit;
}
.footer__form-submit:hover {
    background-color: #ffb203;
}

.footer__form-image {
    grid-column: span 3;
    width: 91.9%;
    height: auto;
}

.footer__preloader {
    height: 189px;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media (max-width: 1279px) {
    .footer__reviews-recent {
        width: 640px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
    }

    .review {
        height: auto;
    }

    .footer__swiper-title {
        text-align: center;
    }

    .footer__form {
        position: relative;
        width: 640px;
        margin: 20px auto 0;    
        justify-items: center;
    }

    .footer__form-title {
        justify-self: center;
    }

    .footer__preloader {
        height: auto;
    }
}



@media (max-width: 660px) {
    .footer__reviews-recent {
        width: 95%;
    }

    .review {
        width: calc(100% - 118px);
        font-size: 14px;
        min-height: 90px;
    }
    
    .review__span {
        font-size: 11pt;
    }

    .footer__form {
        width: 95%;
        grid-template-columns: repeat(2, 1fr);
    }

    .footer__form-title {
        grid-column: span 2;
    }

    .footer__form-input {
        width: 95%;
    }

    .footer__form-textarea {
        width: 95%;
    }

    .footer__form-submit {
        grid-column: span 2;
    }

    .footer__form-image {
        grid-column: span 2;
    }
}