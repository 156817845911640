.contacts {
    background: #e3e3e3;
    margin: 40px 0;
    font-family: Lato,Arial,Helvetica,sans-serif;
    color: #292928;
}

.contacts__container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px 0;
}

.contacts__title {
    font-size: 1.5em;
    font-weight: 500;
    border-bottom: 1px solid #b01e1e;
    padding: 0 0 16px;
    width: 40%;
    margin: 20px 0 20px 10px;
}

.leaflet-container {
    height: 155px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}

.contacts__place {
    display: flex;
    border-bottom: 2px solid #fbb500;
    padding: 0 0 25px;
    margin: 30px 0 0;
}
.contacts__place:nth-of-type(2n) {
    flex-direction: row-reverse;
}
.contacts__place:last-of-type {
    border-bottom: none;
}

.contacts__place-info {
    width: 50%;
    padding: 0 10px;
}

.contacts__place-icon {
    color: #b70d16;
}

.contacts__place-text {
}

.contacts__swaper {
    width: 50%;
}

.contacts__swape-image {
    width: 100%;
}

.contacts__swiper-button-prev {
    color: #a40000;
}
.contacts__swiper-button-prev:hover {
    color: #ffb203;
}
.contacts__swiper-button-next {
    color: #a40000;
}
.contacts__swiper-button-next:hover {
    color: #ffb203;
}

.contacts__info {
    border: 1px solid #dedede;
    box-shadow: 0 0 2px #dedede;
}
.contacts__info-tr {
    height: 60px;
}
.contacts__info-tr:nth-of-type(2n) {
    background-color: #f4f4f4;
}
.contacts__info-td {
    padding: 0 20px;
}



@media (max-width: 911px) {
    .contacts__title {
        width: 95%;
        text-align: center;
        margin: 20px auto;
    }

    .contacts__place {
        flex-direction: column;
        align-items: center;
    }
    .contacts__place:nth-of-type(2n) {
        flex-direction: column;
    }

    .contacts__place-info {
        width: 80%;
        padding: 0 5px;
    }

    .contacts__swaper {
        width: 80%;
    }
}



@media (max-width: 360px) {
    .contacts__info-td {
        padding: 5px;
    }
}