.about {
    background: #e3e3e3;
    margin: 40px 0;
    font-family: Lato,Arial,Helvetica,sans-serif;
    color: #292928;
}

.about__container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px 0;
}

.about__title {
    font-size: 1.5em;
    font-weight: 500;
    border-bottom: 1px solid #b01e1e;
    padding: 0 0 16px;
    width: 40%;
    margin: 20px 0 20px 10px;
}

.about__text {
    padding: 0 5px;
}

.about__rewards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
}

.about__reward {
    width: 25%;
    min-width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.about__reward-image {
    border: 7px solid #ad1e20;
}

.about__team {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}

.about__teammate {
    color: #282828;
    width: 225px;
    height: 450px;
    background-color: #fff;
    box-shadow: 0 0 10px #d3d3d3;
    line-height: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.about__teammate-photo {
    margin: 12px 0 0;
}

.about__teammate-name {
    text-align: center;
    font-weight: 500;
    font-size: 15pt;
    margin: 5px 0 0;
}

.about__teammate-post {
    text-align: center;
    font-style: italic;
    margin: 5px 0 0;
    color: #fff;
    width: 140px;
    display: block;
}
.about__teammate-post_red {
    background-color: #991312;
}
.about__teammate-post_green {
    background-color: #389912;
}
.about__teammate-post_blue {
    background-color: #1684b8;
}
.about__teammate-post_yellow {
    background-color: #e6a205;
}

.about__teammate-role {
    margin: 5px 0 0;
    text-align: center;
    font-size: 11pt;
    line-height: 16pt;
    padding: 0 5px;
}



@media (max-width: 1000px) {
    .about__team {
        grid-template-columns: repeat(3, 1fr);
    }
}



@media (max-width: 911px) {
    .about__title {
        width: 95%;
        text-align: center;
        margin: 20px auto;
    }
}



@media (max-width: 730px) {
    .about__team {
        grid-template-columns: repeat(2, 1fr);
    }
}



@media (max-width: 485px) {
    .about__team {
        grid-template-columns: 1fr;
    }
}