.corporatives {
    background: #e3e3e3;
    margin: 40px 0;
    font-family: Lato,Arial,Helvetica,sans-serif;
    color: #292928;
}

.corporatives__container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px 0;
}

.corporatives__title {
    font-size: 1.5em;
    font-weight: 500;
    border-bottom: 1px solid #b01e1e;
    padding: 0 0 16px;
    width: 40%;
    margin: 20px 0 20px 10px;
}

.corporatives__text {
    padding: 0 5px;
}

.corporatives__swaper {
    width: 60%;
    margin: 0 auto;
}



@media (max-width: 911px) {
    .corporatives__title {
        width: 95%;
        text-align: center;
        margin: 20px auto;
    }

    .corporatives__swaper {
        width: 70%;
    }
}



@media (max-width: 580px) {
    .corporatives__swaper {
        width: 90%;
    }
}