.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    z-index: 4;
    font-family: Lato,Arial,Helvetica,sans-serif;
    transition: all .2s ease-in-out;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}
.popup_opened {
    visibility: visible;
    opacity: 1;
    overflow: scroll;
    pointer-events: all;
}
.popup__container {
    display: flex;
    margin: 10% auto;
    background: #e3e3e3;
    width: 820px;
    min-height: 275px;
    /* padding: 25px; */
    padding: 25px 25px 25px 0;
    border-radius: 20px;
    position: relative;
}

.popup__image-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup__image {
    height: 275px;
    width: 275px;
}

.popup__item {
    position: relative;
    width: 50%;
}

.popup__item-name{
    margin: 0 0 35px;
    text-transform: uppercase;
    font-size: 18.72px;
    font-weight: 500;
    border-bottom: 1px solid #a40000;
    padding: 0 0 10px;
}

.popup__item-info {
    margin: 16px 0;

}

.popup__item-info-span {
    color: rgb(164, 0, 0);
}

.popup__item-footnote {
    font-size: 13px;
}

.popup__item-form {
    margin: 0 0 50px;
}

.popup__item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: -10px;
    width: 100%;
}

.popup__item-price {
    font-size: 21px;
    margin: 0;
}

.popup__item-price-span {
    color: rgb(164, 0, 0);
}

.popup__item-purchase {
    font-size: 16pt;
    height: 40px;
    border: none;
    color: #fff;
    border-radius: 60px;
    background-color: rgba(164,0,0,.643137);
    width: 120px;
    transition: all .2s ease-in-out;
    cursor: pointer;
}
.popup__item-purchase:hover {
    background-color: #a40000;
}

.popup__close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    color: rgba(255,0,0,.427451);
    transition: all .2s ease-in-out;
    transform: scale(1.3);
}
.popup__close:hover {
    transform: scale(1.85);
}

.popup__conflict {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10% auto;
    background: #e3e3e3;
    /* width: 80%; */
    width: 725px;
    min-height: 275px;
    padding: 25px;
    border-radius: 20px;
    position: relative;
}

.popup__conflict-button {
    margin: 20px 0;
    height: 30px;
    border: none;
    color: #fff;
    border-radius: 20px;
    width: 55px;
    background-color: rgba(164,0,0,.643137);
    transition: all .2s ease-in-out;
    font-size: 13pt;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup__conflict-button:hover {
    background-color: #a40000;
}

.popup__item-buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(164,0,0,.643137);
    border-radius: 20px;
    width: 100px;
    height: 40px;
    font-size: 15pt;
    color: #fff;
}

.popup__item-portion-x {
    margin: 0;
}

.popup__item-portion-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 15pt;
    transition: all .2s ease-in-out;
    margin: 0;
    padding: 0 6px;
    cursor: pointer;
}
.popup__item-portion-btn_remove:hover {
    color: rgba(255,0,0,.427451);
}
.popup__item-portion-btn_add:hover {
    color: #39ad1c;
}

@media (max-width: 911px) {
    .popup__container {
        width: 90%;
    }

    .popup__conflict {
        width: 80%;
    }
}



@media (max-width: 670px) {
    .popup__container {
        min-height: auto;
        display: flex;
        flex-direction: column;
        padding: 25px;
        box-sizing: border-box;
        align-items: center;
    }

    .popup__image-container {
        width: 90%;
    }

    .popup__image {
        height: 190px;
        width: 190px;
    }

    .popup__item {
        width: 90%;

    }

    .popup__item-name {
        margin: 0 0 15px;
        font-size: 13px;
        padding: 0 0 5px;
    }

    .popup__item-info {
        margin: 5px 0;
        font-size: 13px;
    }

    .popup__item-footnote {
        font-size: 12px;
        margin: 8px 0;
    }
    
    .popup__item-form {
        margin: 0 0 70px;
    }

    .popup__item-label {
        font-size: 10pt;
    }

    .popup__item-price {
        font-size: 18px;
    }

    .popup__item-container {
        row-gap: 10px;
        flex-direction: column;
    }

    .popup__item-purchase {
        height: 41px;
        width: 100%;
    }
}