.popup-plase-select {
    background: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 4;
}

.place-select {
    position: relative;
    margin: 10% auto;
    background: #e3e3e3;
    width: 390px;
    min-height: 200px;
    padding: 25px;
    cursor: auto;
    border-radius: 20px;
    color: #000;
    text-align: left;
}

.place-select-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    color: rgba(255,0,0,.427451);
    transition: all .2s ease-in-out;
    transform: scale(1.3);
}
.place-select-close:hover {
    transform: scale(1.85);
}

.place-select-title {
    font-size: 18px;
    font-weight: 500;
    width: 80%;
    border-bottom: 1px solid #a40000;
    margin: 0;
    padding: 0 0 10px;
}

.place-select-groups {
    margin: 20px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
    font-size: 18px;
}

.place-select-group-name {

}

.place-select-place {
    transition: all .2s ease-in-out;
    height: 30px;
    cursor: pointer;
}
.place-select-place:hover {
    font-size: 16pt;
    font-weight: 400;
}

.place-select-buttons {
    display: flex;
    justify-content: space-around;
}

.place-select-button {
    margin: 20px 0;
    height: 30px;
    border: none;
    color: #fff;
    border-radius: 20px;
    width: 55px;
    background-color: rgba(164,0,0,.643137);
    transition: all .2s ease-in-out;
    font-size: 13pt;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.place-select-button:hover {
    background-color: #a40000;
}
.place-select-button:disabled {
    background-color: rgba(40,0,0,.643137);
    cursor: auto;
}