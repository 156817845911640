.shippin-and-payment {
    background: #e3e3e3;
    margin: 40px 0;
    font-family: Lato,Arial,Helvetica,sans-serif;
    color: #292928;
}

.shippin-and-payment__container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px 0;
}

.shippin-and-payment__title {
    font-size: 1.5em;
    font-weight: 500;
    border-bottom: 1px solid #b01e1e;
    padding: 0 0 16px;
    width: 40%;
    margin: 20px 0 20px 10px;
}

.shippin-and-payment__info-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.shippin-and-payment__info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shippin-and-payment__info-image {
    width: 200px;
    height: 210px;
}

.shippin-and-payment__info-block-title {
    font-weight: 500;
    font-size: 17pt;
}

.shippin-and-payment__info-block-text {
    font-size: 13pt;
    width: 300px;
    text-align: center;
}

.shippin-and-payment__text {
    padding: 0 5px;
}

.shippin-and-payment__images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
}

.shippin-and-payment__image-block {
    width: 20%;
    min-width: 200px;
    height: 89px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid grey;
    box-sizing: border-box;

}
.shippin-and-payment__image-block:last-of-type {
    border-right: none
}

.shippin-and-payment__image {

}



@media (max-width: 1020px) {
    .shippin-and-payment__image-block {
        border: none;
    }
}



@media (max-width: 911px) {
    .shippin-and-payment__title {
        width: 95%;
        text-align: center;
        margin: 20px auto;
    }
}