.popup__order {
    margin: 10% auto;
    background: #e3e3e3;
    width: 725px;
    height: auto;
    padding: 25px;
    border-radius: 20px;
    position: relative;
}

.popup__order-title {
    font-size: 18px;
    font-weight: 500;
    width: 320px;
    border-bottom: 1px solid #a40000;
    margin: 0;
    padding: 0 0 10px;
}

.popup__order-form {
    display: flex;
    flex-wrap: wrap;
}

.popup__order-form-left {
    width: 50%;
}

.popup__order-total {
    font-size: 16pt;
    font-weight: normal;
}
.popup__order-total-span {
    color: #a40000;
}

.popup__order-total-span-promo {
    margin: 0 10px;
    text-decoration: line-through;
}

.popup__order-text {
    display: inline;
    margin: 0 15px 0 0;
}

.popup__order-cutlery-block {
    background-color: rgba(164,0,0,.643137);
    border-radius: 20px;
    border: none;
    width: 74px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.popup__order-cutlery-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 15pt;
    transition: all .2s ease-in-out;
    margin: 0;
    padding: 0 6px;
    cursor: pointer;
}
.popup__order-cutlery-button_remove:hover {
    color: rgba(255,0,0,.427451);
}
.popup__order-cutlery-button_add:hover {
    color: #39ad1c;
}

.popup__order-cutlery {
    margin: 0;
    padding: 0 7px;
}

.popup__order-inuput {
    padding: 0 12px;
    width: 318px;
    outline: none;
    border: 1px solid #9f9f9f;
    transition: all .2s ease-in-out;
    border-radius: 10px;
    height: 38px;
    background-color: #e3e3e3;
    margin: 10px 10px 0 0;
}
.popup__order-inuput::placeholder {
    font-family: Lato,Arial,Helvetica,sans-serif;
    font-size: 17px;
}
.popup__order-inuput:disabled {
    background-color: #cbcbcb;
}
.popup__order-inuput_small {
    box-sizing: content-box;
    width: 141px;
} 
.popup__order-inuput:focus{
    border: 1px solid #a40000;
}
.popup__order-inuput_textarea {
    padding: 12px;
    width: 318px;
    height: 74px;
    resize: none;
}
.popup__order-inuput_promo {
    width: 152px;
}
.popup__order-inuput_error {
    box-shadow: 0 0 5px rgba(255,0,0,.427451);
    border-color: rgba(255,0,0,.427451);
}

.popup__order-submit {
    color: #fff;
    width: 310px;
    height: 50px;
    border: none;
    border-radius: 20px;
    background-color: rgba(164,0,0,.643137);
    font-size: 12pt;
    transition: all .2s ease-in-out;
    cursor: pointer;
    margin: 30px 0 0;
    padding: 0;
}
.popup__order-submit:hover{
    background-color: #a40000;
}

.popup__order-form-right {
    width: 50%;
}

.popup__order-checkbox-label {
    cursor: pointer;
    margin: 0 15px 0 0;
}
.popup__order-checkbox-label_block {
    display: block;
    margin: 10px;
}

.popup__order-checkbox {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip-path: inset(0 0 0 0);
    visibility: hidden;
}
.popup__order-checkbox:checked + .popup__order-visible-checkbox::before { 
    content: "\f00c"; 
}
.popup__order-visible-checkbox {
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: #e3e3e3;
    border: 1px solid #a40000;
    font-family: FontAwesome;
    content: "\f00c";
    color: #a40000;
    font-size: 15px;
    line-height: 14px;
    overflow: hidden;
    margin: 0 5px 0 0;
}

.popup__order-radio-label {
    cursor: pointer;
    margin: 0 15px 11px 0;
    display: block;
}
.popup__order-radio-label_disabled {
    color: #888;
}

.popup__order-radio {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip-path: inset(0 0 0 0);
    visibility: hidden;
}
.popup__order-radio:checked + .popup__order-visible-radio {
    background: radial-gradient(circle,#a40000 6px,#e3e3e3 0);
}

.popup__order-visible-radio {
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: #e3e3e3;
    border-radius: 16px;
    border: 1px solid #a40000;
    margin: 0 5px 0 13px;
}

.popup__order-info {
    font-size: 15px;
}

.popup__order-note {
    color: #555;
    line-height: 20px;
    text-align: center;
    font-size: 11pt;
    margin: 15px 0 0;
}

.popup__order-change-container {
    display: none;
}
.popup__order-change-container_opened {
    display: block;
}

.popup__order-promobox {
    margin: 20px 0 0 16px;
}

.popup__order-promobox-button {
    width: 122px;
    height: 38px;
    color: #fff;
    border: none;
    border-radius: 20px;
    background-color: rgba(164,0,0,.643137);
    font-size: 12pt;
    transition: all .2s ease-in-out;
    cursor: pointer;
    margin: 0 0 0 10px;
}
.popup__order-promobox-button:hover{
    background-color: #a40000
}

.popup__order-promocaptcha {
    margin: 10px 0 0;
}

.popup__order-promobox-text {
    color: #555;
    line-height: 20px;
    text-align: center;
    font-size: 11pt;
    margin: 20px 0 0;
}

.popup__order-error-message {
    color: rgba(255,0,0,.427451);
}

.popup__order-success-message {

}

.street {
    width: 342px;
    margin: 10px 0 0;
}

.street__control {
    border: 1px solid #9f9f9f !important;
    transition: all .2s ease-in-out !important;
    border-radius: 10px !important;
    height: 38px !important;
    background-color: #e3e3e3 !important;
}
.street_error__control {
    box-shadow: 0 0 5px rgba(255,0,0,.427451) !important;
    border: 1px solid #9f9f9f !important;
    border-color: rgba(255,0,0,.427451) !important;
    transition: all .2s ease-in-out !important;
    border-radius: 10px !important;
    height: 38px !important;
    background-color: #e3e3e3 !important;
}

.street__control--is-focused {
    border: 1px solid #a40000 !important;
    box-shadow: none !important;
}

.pkform {
    visibility: hidden;
}

.popup__order-container-button {
    width: 80%;
    color: #fff;
    height: 50px;
    border: none;
    border-radius: 20px;
    background-color: rgba(164,0,0,.643137);
    font-size: 12pt;
    transition: all .2s ease-in-out;
    cursor: pointer;
    margin: 0;
    padding: 0;
}
.popup__order-container-button:hover {
    background-color: #a40000;
}

.popup__order-containers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 10px
}

.popup__order-containers-button {
    width: 40%;
    color: #fff;
    height: 50px;
    border: none;
    border-radius: 20px;
    background-color: rgba(164,0,0,.643137);
    font-size: 12pt;
    transition: all .2s ease-in-out;
    cursor: pointer;
    margin: 0;
    padding: 0;
}
.popup__order-containers-button:hover{
    background-color: #a40000;
}

.popup__order-containers-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup__order-containers-title {
    font-size: 12pt;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    overflow: hidden;
}

.popup__order-containers-select {
    padding: 0;
    outline: none;
    border: 1px solid #9f9f9f;
    transition: all .2s ease-in-out;
    border-radius: 10px;
    height: 38px;
    background-color: #e3e3e3;
    margin: 0;
    width: 21%;
    padding: 0 0 0 10px;
}


@media (max-width: 840px) {
    .popup__order {
        width: 95%;
        box-sizing: border-box;
    }

    .popup__order-title {
        width: 45%; 
        font-size: 16px;
    }

    .popup__order-total {
        font-size: 14pt;
    }

    .popup__order-inuput {
        width: 90%;
        box-sizing: border-box;
        appearance: none;
        padding: 0 5px;
    }
    .popup__order-inuput::placeholder {
        font-size: 13px;
    }
    
    .popup__order-inuput_small {
        width: calc((90% - 10px) / 2)
    } 
    .popup__order-inuput_small:first-of-type {
        margin: 0 10px 0 0;
    } 
    .popup__order-inuput_textarea {
        padding: 5px;
        width: 90%;
    }
    .popup__order-inuput_promo {
        width: 152px;
    }

    .popup__order-radio-label {
        margin: 0;
    }

    .popup__order-info {
        font-size: 13px;
    }

    .popup__order-submit {
        width: 310px;
        height: 50px;
        font-size: 12pt;
        margin: 20px auto 0;
    }

    .street {
        width: 90%;
    }
}



@media (max-width: 720px) {
    .popup__order {
        padding: 15px;
    }

    .popup__order-title {
        width: 100%;
        text-align: center;
    }

    .popup__order-form {
        flex-direction: column;
        align-items: center;
    }

    .popup__order-form-left {
        width: 100%;
    }

    .popup__order-form-right {
        width: 100%;
    }

    .popup__order-inuput {
        width: 95%;
    }
    .popup__order-inuput_small {
        width: calc((95% - 10px) / 2)
    }
    .popup__order-inuput_textarea {
        margin: 10px 0;
    }

    .popup__order-note {
        font-size: 8pt; 
    }

    .popup__order-submit {
        width: 100%;
    }

    .popup__order-promobox {
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .popup__order-promobox-button {
        width: 80%;
        margin: 10px auto 0;
    }

    .popup__order-promocaptcha {
        margin: 10px auto 0;
    }

    .popup__order-error-message {
        position: fixed;
        background-color: #fff;
        top: 0;
        width: 90%;
        left: 5%;
        padding: 15px;
        border-radius: 5px;
        z-index: 1;
        text-align: center;
        box-sizing: border-box;
    }

    .street {
        width: 95%;
    }
}