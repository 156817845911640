body {
  margin: 0;
  height: 100vh;
  background: linear-gradient(90deg,#b93c3c,#a40000);
}
@import url(./vendor/css/font-awesome.min.css);
@import url(./css/components/header.css);
@import url(./css/components/footer.css);
@import url(./css/components/food.css);
@import url(./css/components/popup.css);
@import url(./css/components/popup-order.css);
@import url(./css/components/menu.css);
@import url(./css/components/popup-plase-select.css);
@import url(./css/components/popup-lk.css);
@import url(./css/components/shippin-and-payment.css);
@import url(./css/components/about.css);
@import url(./css/components/contacts.css);
@import url(./css/components/corporatives.css);
@import url(./css/components/tracking.css);
@import url(./css/components/popup-complex.css);