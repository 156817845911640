.tracking {
    background: #e3e3e3;
    margin: 40px 0;
    font-family: Lato,Arial,Helvetica,sans-serif;
    color: #292928;
}

.tracking__container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px 0;
}

.tracking__title {
    font-size: 1.5em;
    font-weight: 500;
    border-bottom: 1px solid #b01e1e;
    padding: 0 0 16px;
    width: 40%;
    margin: 20px 0 20px 10px;
}

.tracking__orders {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 20px;
}

.tracking__block {
    width: 300px;
    height: 420px;
    box-shadow: 0 0 5px #d3d3d3;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.tracking__list {
    height: 360px;
    overflow: auto;
    list-style-type: none;
    padding: 0;
    scrollbar-color: rgba(164,0,0,.643137) #ccc;;
    scrollbar-width: thin;
    margin: 0;
}
.tracking__list::-webkit-scrollbar{
    width: 10px;
    background-color: #ccc;
}
.tracking__list::-webkit-scrollbar-thumb {
    background-color: rgba(164,0,0,.643137);
    border-radius: 9em;
}

.tracking__list-header {
    font-size: 16pt;
    line-height: 45pt;
    background: linear-gradient(270deg,#a40000,#b93c3c);
    color: #fff;
    text-align: left;
    padding: 0 0 0 20px;
    margin: 0;
    border-radius: 10px 10px 0 0;
}
.tracking__list-header_small {
    font-size: 12pt;
    line-height: 22pt;
}

.tracking__list-header-span {
    font-size: 11pt;
    margin: 0 0 0 80px;
}

.tracking__list-li {
    height: 60px;
    line-height: 42pt;
    color: #282828;
    padding: 0 0 0 20px;
}
.tracking__list-li:nth-of-type(2n) {
    background-color:  #f4f4f4;
}

.tracking__list-li-name {
    color: #6e6e6e;
    font-size: 11pt;
    float: right;
    margin: 0 20px 0 0;
}

.tracking__list-li-your {
    background-color: #ffb203;
    float: right;
    margin: 14px 20px 0 0;
    line-height: 23pt;
    color: #282828;
}
.tracking__list-li-your_inaway {
    float: none;
    margin: 0 0 0 5px;
    padding: 5px;
}

.tracking__list-wait {
    width: 100%;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14pt;
    color: #a9a9a9;
    column-gap: 5px;
}

.tracking__couriers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 20px;
}

.tracking__courier {
    display: flex;
    flex-direction: column;
    align-items: center;
}



@media (max-width: 911px) {
    .tracking__title {
        width: 95%;
        text-align: center;
        margin: 20px auto;
    }
}