.lk-popup {
    margin: 10% auto;
    background: #e3e3e3;
    width: 725px;
    height: auto;
    padding: 25px;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.lk-popup__form {
    width: 50%;
}

.lk-popup__form-title {
    font-size: 18px;
    font-weight: 500;
    width: 320px;
    border-bottom: 1px solid #a40000;
    margin: 0;
    padding: 0 0 10px;
}

.lk-popup__form-input {
    padding: 0 12px;
    width: 316px;
    outline: none;
    border: 1px solid #9f9f9f;
    transition: all .2s ease-in-out;
    border-radius: 10px;
    height: 38px;
    background-color: #e3e3e3;
    margin: 10px 10px 0 0;
    box-sizing: content-box;
}
.lk-popup__form-input_small {
    box-sizing: content-box;
    width: 141px;
}
.lk-popup__form-input_error {
    box-shadow: 0 0 5px rgba(255,0,0,.427451);
    border-color: rgba(255,0,0,.427451);
}
.lk-popup__form-input::placeholder {
    font-family: Lato,Arial,Helvetica,sans-serif;
    font-size: 17px;
}
.lk-popup__form-input:disabled {
    background-color: #cbcbcb;
}
.lk-popup__form-input:focus {
    border: 1px solid #a40000;
}

.lk-popup__form-login {
    display: inline-block;
    color: #fff;
    width: 147px;
    height: 32px;
    border: none;
    border-radius: 20px;
    background-color: rgba(164,0,0,.643137);
    font-size: 12pt;
    transition: all .2s ease-in-out;
}
.lk-popup__form-login:hover {
    background-color: #a40000;
}
.lk-popup__form-login_recovery {
    width: 208px;
}

.lk-popup__form-pass-recovery {
    cursor: pointer;
    text-decoration: underline;
    margin: 30px 0 0 50px;
    display: inline-block;
}

.lk-popup__form-checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 0;
}

.lk-popup__form-checkbox {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip-path: inset(0 0 0 0);
    visibility: hidden;
}
.lk-popup__form-checkbox:checked + .lk-popup__form-visible-checkbox::before { 
    content: "\f00c"; 
}
.lk-popup__form-visible-checkbox {
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: #e3e3e3;
    border: 1px solid #a40000;
    font-family: FontAwesome;
    content: "\f00c";
    color: #a40000;
    font-size: 15px;
    line-height: 14px;
    overflow: hidden;
    margin: 0 5px 0 0;
}

.lk-popup__form-register {
    color: #fff;
    width: 193px;
    height: 32px;
    border: none;
    border-radius: 20px;
    background-color: rgba(164,0,0,.643137);
    font-size: 12pt;
    transition: all .2s ease-in-out;
    margin: 30px 0 0;
}
.lk-popup__form-register:hover {
    background-color: #a40000;
}

.lk-popup__note {
    color: #555;
    line-height: 20px;
    text-align: center;
    font-size: 11pt;
    margin: 20px 0 0;
}

.lk-popup__form-promocaptcha {
    margin: 10px 0 0;
}

.lk-popup__error-message {
    width: 100%;
    text-align: center;
    color: rgba(255,0,0,.427451);
}

.lk-popup__success-message {
    width: 100%;
    text-align: center;
     color: #39ad1c;
}

.lk-popup__tab-header {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 3px solid #a40000;
}

.lk-popup__tab {
    color: #fff;
    background-color: rgba(164,0,0,.643137);
    width: 200px;
    height: 38px;
    cursor: pointer;
    border-radius: 10px 10px 0 0;
    transition: all .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.lk-popup__tab_active {
    background-color: #a40000;
}
.lk-popup__tab:hover {
    background-color: #a40000;
}

.lk-popup__main {
    display: flex;
}

.lk-popup__block {
    width: 50%;
}

.lk-popup__balance {
    color: #a40000;
    font-size: 32pt;
}

.lk-popup__button {
    color: #fff;
    width: 220px;
    height: 32px;
    border: none;
    border-radius: 20px;
    background-color: rgba(164,0,0,.643137);
    font-size: 12pt;
    margin: 30px 0 0;
    transition: all .2s ease-in-out;
}
.lk-popup__button:hover {
    background-color: #a40000;
}

@media (max-width: 840px) {
    .lk-popup {
        width: 95%;
        box-sizing: border-box;
    }

    .lk-popup__form-title {
        width: 45%; 
        font-size: 16px;
    }

    .lk-popup__form-input {
        width: 90%;
        box-sizing: border-box;
        appearance: none;
        padding: 0 5px;
    }
    .lk-popup__form-input::placeholder {
        font-size: 13px;
    }
    
    .lk-popup__form-input_small {
        width: calc((90% - 10px) / 2)
    } 

    .lk-popup__form-login{
        height: 50px;
        font-size: 12pt;
        margin: 20px auto 0;
        width: 90%;
    }

    .lk-popup__form-register {
        height: 50px;
        font-size: 12pt;
        margin: 20px auto 0;
        width: 90%;
    }

    .lk-popup__form-pass-recovery {
        margin: 30px auto 0;
    }

    .lk-popup__button {
        width: 95%;
        height: 50px;
    }
}



@media (max-width: 720px) {
    .lk-popup {
        padding: 15px;
        flex-direction: column;
        row-gap: 20px;
    }

    .lk-popup__form-title {
        width: 100%;
        text-align: center;
    }

    .lk-popup__form {
        width: 100%;
    }

    .lk-popup__form-input {
        width: 95%;
    }
    .lk-popup__form-input_small {
        width: calc((95% - 10px) / 2)
    }

    .lk-popup__form-checkbox-label {
        justify-content: center;
    }

    .lk-popup__note {
        font-size: 8pt; 
    }

    .lk-popup__form-login {
        width: 100%;
    }

    .lk-popup__form-register {
        width: 100%;
    }

    .lk-popup__form-promocaptcha {
        display: flex;
        justify-content: center;
    }

    .lk-popup__main {
        flex-direction: column;
    }
    
    .lk-popup__block {
        width: 100%;
    }
}
